<template>
  <div>
    <component ref="model" :is="getComonent(config.modelName||'Model-1')" @ready="modelReady">
      <div style="position:relative">
        <template v-if="link">
          <template v-if="link.type=='Location'">
            <div style="width:90vw;height:90vh;position:relative">
              <!--<MapView :mark="{lat:link.gpsLat,lng:link.gpsLng}" :dragable="true" height="100%"></MapView>-->
              <!--<PanoLeafletMap ref="map" :currentItem="link" :currentLoc="{lat:link.gpsLat,lng:link.gpsLng}" :currentItemConfig="linkItemConfig" :zoom="16" :autoZoom="false" :mapCenter="{lat:link.gpsLat,lng:link.gpsLng}"></PanoLeafletMap>-->
              <PanoLeafletMap ref="PanoMap"  :currentItem="link" :currentLoc="{lat:link.gpsLat,lng:link.gpsLng}" :mapCenter="{lng:link.gpsLng,lat:link.gpsLat}" :zoom="16">
                <template v-slot:buttons>
                  <button @click="mapBackToPano" style="line-height: 2rem;height: 2.5rem;">回到目标位置</button>
                </template>
              </PanoLeafletMap>
            </div>
          </template>
          <template v-if="link.type=='Image'">
            <div>
              <img :src="link.image.url" />
            </div>
          </template>
        </template>
        <i style="position:absolute;top:3px;right:3px;padding:5px;z-index: 1000;" class="fas fa-times text-shadow" @click="modelHide()" title="关闭" />
      </div>
    </component>
  </div>
</template>
<script>
  import PanoLeafletMap from '../../Map/PanoLeafletMap'
  export default {
    components: {
      PanoLeafletMap,
    },
    props: {
      config: {
        default() {
          return {}
        }
      },
    },
    data() {
      return {
        modelShow: null,
        modelHide: null,
        link: null,
      }
    },
    inject: {
      getFunc: {},
      getComonent: {},
    },
    computed: {
    },
    created() {
    },
    mounted() {
      this.$emit('ready', {
        vm: this,
        events: {
        },
        funcs: {
          linkShowView: this.linkShowView,
          show: this.show,
        }
      })
    },
    destroyed() {
    },
    methods: {
      modelReady({ funcs }) {
        this.modelShow = funcs.show
        this.modelHide = funcs.hide
      },
      show() {
        if (this.modelShow) {
          this.modelShow()
        } else {
          alert('模态框还没有准备好')
        }
      },
      linkShowView(item) {
        console.log('linkShowView', item)
        this.link = item
        this.show()
      },
      mapBackToPano() {
        this.$refs.PanoMap.mapBackToPano()
      },
    },
  }
</script>
<style scoped>
  .panoinfo {
    width: 70vw;
    max-width: 600px;
    min-width: 240px;
    color: #fff;
  }

  .exif {
    font-size: 1.2rem
  }

    .exif tr:first-child {
      white-space: nowrap
    }

    .exif td {
      padding: 0 0 0 0;
      border-bottom: none;
    }

    .exif th {
      padding: 0 0 0 0;
      text-align: right;
      width: 6em;
      vertical-align: top;
      border-bottom: none;
    }
</style>
